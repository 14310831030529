<template lang="pug">
    .main-wrapper.atendimento-medico
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Atendimento Médico</b>

        Dialog.possibilitar-retorno-dialog(header='Possibilitar retorno' :visible.sync='dialogPossibilitarRetorno' :modal='true')
            p Liberar retorno do paciente: <b>{{ possibilitarRetorno_data.nm_paciente }}</b>?
            .ta-center
                ProgressSpinner(v-if='waitingPossibilitarRetorno' strokeWidth='4')
                Button.p-button-text(v-else label='Sim' icon="jam jam-check" @click='possibilitarRetorno()')

        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-2
                    label.form-label Cód. guia:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.cd_guia'
                            @keyup='checkEmptyField("cd_guia")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Nome Paciente:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_paciente'
                            @keyup='checkEmptyField("nm_paciente")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                //.p-col-12.p-md-4
                    label.form-label Especialidade:
                    Dropdown( v-model='filters.especialidade' :options='options.especialidades'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

                .p-col-12.p-md-4
                    label.form-label Situação:
                    MultiSelect.input-ieStatus(
                        v-model='filters.ie_status'
                        :options='options.ie_status'
                        dataKey='value'
                        optionLabel='text'
                        optionValue='value'
                        placeholder='Selecione'
                        @change='applyFilters()')
                    //Dropdown( v-model='filters.ie_status' :options='options.ie_status'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

                .p-col-12.p-md-2
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

        .p-grid.p-fluid.box-calendar.p-align-center(style='margin: 20px auto; max-width: 500px;' v-if="filters.nm_paciente == ''")
            .p-col-2.ta-left
                Button.p-button-raised.p-button-rounded(icon='jam jam-chevron-left' @click='prevDay()')
            .p-col-8.ta-center
                Calendar( v-model='filters.dt_inicio' dateFormat="dd/mm/yy" :locale="ptbr"
                    :manualInput='false' :touchUI='windowInnerWidth < 576')
            .p-col-2.ta-right
                Button.p-button-raised.p-button-rounded(icon='jam jam-chevron-right' @click='nextDay()')

        ProgressBar(v-if='waiting' mode="indeterminate" style='margin: 36px auto;')
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.

        div(v-else)

            DataView.my-2.dataview(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em")
                        Panel.panel-list.ta-center(:header="props.data.hr_agenda_f")
                            .ta-left
                                p <b>Paciente:</b> <span style='font-size: 16px;'>{{ props.data.nm_paciente }}</span>
                                p <b>Código da guia:</b> {{ props.data.cdGuiaEletronica }}
                                p <b>Data:</b> {{ props.data.dt_agenda }}
                                p <b>Horário:</b> {{ props.data.hr_agenda_f }}
                                p <b>Especialidade:</b> {{ props.data.ds_especialidade }}
                                p <b>Situação: </b>
                                    span.mtd-badge(:style="{ 'background-color': props.data.status_color, 'color': '#fff'}") {{ props.data.ie_status_f }}
                            .actions-wrapper.ta-right.mt-4.mb-1
                                Button.mr-1(icon='jam jam-play-circle' label='Atender' @click='iniciarAtendimento(props.data.id)')
                                Button.ma-1(v-if="props.data.ie_marcado_retorno && !props.data.ie_retorno" icon='jam jam-repeat' label='Possibilitar retorno'
                                    @click="dialogPossibilitarRetorno=true; possibilitarRetorno_data=props.data")
            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable(header='Lista de agendamentos')
                DataTable(:value="list")
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_agenda' header='Data')
                    Column(headerStyle='width: 6%;' bodyStyle='text-align: center;' field='hr_agenda_f' header='Horário')
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='cdGuiaEletronica' header='Cód. guia')
                    Column(headerStyle='width: 26%;' header='Paciente')
                        template(#body='props')
                            span <b>{{ props.data.nm_paciente }}</b><br/>
                                | Idade: {{ props.data.idade }} anos<br/>
                                | Fone: {{ $root.applyTelefoneMask(props.data.nr_telefone_paciente) }}
                    Column(headerStyle='width: 10%;' bodyStyle='padding: 0;' header="Situação")
                        template(#body='props')
                            .cell(:style="{ 'background-color': props.data.status_color}") {{ props.data.ie_status_f }}
                    Column(headerStyle='width: 20%;' field='ds_especialidade' header='Especialidade')
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='ie_prioridade_f' header='Prioridade')
                    Column(headerStyle='width: 8%; text-align: center' header="Ações")
                        template(#body='props')
                            .actions-wrapper.ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    :icon="['A', 'AG'].includes(props.data.ie_status) ? 'jam jam-play-circle' : 'jam jam-eye'"
                                    v-tooltip.top="['A', 'AG'].includes(props.data.ie_status) ? 'Atender' : 'Visualizar'"
                                    @click='iniciarAtendimento(props.data.id)'
                                )
                                Button.p-button-raised.p-button-rounded(
                                    v-if="props.data.ie_marcado_retorno && !props.data.ie_retorno" icon="jam jam-repeat"
                                    v-tooltip.top="'Possibilitar retorno'"
                                    @click="dialogPossibilitarRetorno=true; possibilitarRetorno_data=props.data"
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .atendimento-medico {
        .actions-wrapper {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .box-calendar {
            .p-inputtext, .jam {
                text-align: center;
                font-size: 18px;
                font-weight: 700;
            }
        }
        .panel-list {
            .p-panel-title {
                font-size: 16px;
            }
        }
        .possibilitar-retorno-dialog {
            .p-dialog-content {
                overflow-y: auto !important;
            }
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
        .input-ieStatus .p-multiselect-header {
            display: none;
        }
    }
</style>

<script>
    import DataView from 'primevue/dataview'
    import Dialog from 'primevue/dialog'
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import Dropdown from 'primevue/dropdown'
    import MultiSelect from 'primevue/multiselect'
    import Calendar from 'primevue/calendar'
    import ProgressBar from 'primevue/progressbar'
    import Button from 'primevue/button'
    import Paginator from 'primevue/paginator'
    import ProgressSpinner from 'primevue/progressspinner'
    import Tooltip from 'primevue/tooltip'

    import moment from 'moment'
    import { Agendamento, Agenda } from './../../middleware'
    import { pCalendarLocale_ptbr } from './../../utils'
	import wsConfigs from './../../middleware/configs'
    export default {
        components: { DataView, Dialog, Panel, ProgressBar, Calendar, Button, MultiSelect,
            InputText, Dropdown, Column, DataTable, Paginator, ProgressSpinner, Tooltip },
        directives: { tooltip: Tooltip },
        created () {
            this.applyFilters()
        },
        watch: {
            'filters.dt_inicio': function (val) {
                this.filters.dt_fim = val
                this.applyFilters()
            }
        },
        data () {
            return {
                list: [],
                ptbr: pCalendarLocale_ptbr,
                windowInnerWidth: window.innerWidth,
                waiting: false,
                dialogPossibilitarRetorno: false,
                waitingPossibilitarRetorno: false,
                possibilitarRetorno_data: {},
                filters: {
                    dt_inicio: moment()._d,
                    dt_fim: moment()._d,
                    ie_status: ['A','EX','AG'],
                    cd_guia: null,
                    especialidade: null,
                    nm_paciente: '',
                },
                options: {
                    especialidades: [],
                    ie_status: [
                        { value: 'A', text: 'Agendado', color: '#faf3dd' },
                        { value: 'EX', text: 'Executado', color: '#e4f8e1' },
                        { value: 'AG', text: 'Aguardando', color: '#34a9eb' }
                    ]
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        methods: {
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }

                //params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`

                if (this.$route.query.tb != this.filters.ie_tipo_agenda || this.$route.query.pg != this.paginator.page)
                    this.$router.replace( { query: { tb: this.filters.ie_tipo_agenda, pg: this.paginator.page } } )

                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key]) {
                        if (key.substr(0, 3) == 'dt_')
                            params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
                        else params[key] = this.filters[key]
                    }
                })

                if( this.filters['nm_paciente'] != '' ){
                    this.filters['dt_inicio'] = null
                    this.filters['dt_fim'] = null
                }

                //this.getEspecialidades()
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            getList (params) {
                let vm = this
                vm.waiting = true
                vm.list = []
                Agendamento.findAllByMedico(params).then(response => {
                    if (response.status == 200) {
                        let ie_status_obj = {}
                        this.options.ie_status.forEach(e => {
                            if (e.value) ie_status_obj[e.value] = { text: e.text, color: e.color }
                        })
                        this.paginator.count = response.data.count

                        if (! response.data.detail) vm.list = response.data.results
                        vm.list.forEach(entry => {
                            entry.dt_agenda = moment(entry.dt_agenda).format('DD/MM/YYYY')
                            let aux = entry.hr_agenda.split(':')
                            entry.hr_agenda_f = `${ aux[0] }:${ aux[1] }`
                            entry.ie_status_f = ie_status_obj[entry.ie_status]?.text
                            entry.status_color = ie_status_obj[entry.ie_status]?.color
                            entry.ie_prioridade_f = entry.ie_prioridade ? 'Prioridade' : 'Normal'
                            entry.cdGuiaEletronica = entry.guiaeletronica_set[0] ? entry.guiaeletronica_set[0] : ''
                            entry.idade = entry.dt_nascimento_paciente ? moment().diff(
                                moment(entry.dt_nascimento_paciente, 'YYYY-MM-DD'), 'years'
                            ) :  ""
                        })
                        vm.waiting = false
                    }
                })
            },
            iniciarAtendimento (id) {
                this.$router.push(`/atendimento-medico/atendimento/${ id }/`)
            },
            checkEmptyField (field) {
                if (! this.filters[field].length) this.applyFilters()
            },
            /*
            getEspecialidades() {
                this.options.especialidades = []
                this.options.especialidades.push({ text: 'TODAS', value: null })
                Agendamento.getEspecialidades().then(response => {
                    if (response.status == 200) {
                        if (response.data.detail) this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.forEach(e => this.options.especialidades.push({ text: e.nm_especialidade, value: e.id }))
                    } else {
                        if (response.data.detail) this.$toast.error(response.data.detail, { duration: 3000 })
                    }
                })
            },
            */
            nextDay () {
                this.filters.nm_paciente = '';
                if( this.filters.dt_inicio )
                    this.filters.dt_inicio = moment(this.filters.dt_inicio).add(1, 'days')._d
                else
                    this.filters.dt_inicio = moment().add(1, 'days')._d
            },
            prevDay () {
                this.filters.nm_paciente = '';
                if( this.filters.dt_inicio )
                    this.filters.dt_inicio = moment(this.filters.dt_inicio).subtract(1, 'days')._d
                else
                    this.filters.dt_inicio = moment().subtract(1, 'days')._d
            },
            possibilitarRetorno () {
                this.waitingPossibilitarRetorno = true
                Agenda.possibilitarRetorno(this.possibilitarRetorno_data.id).then(response => {
                    this.waitingPossibilitarRetorno = false
                    if ([200, 201, 204].includes(response.status)) {
                        this.$toast.info('Ação executada com sucesso', { duration: 3000 })
                        this.dialogPossibilitarRetorno = false
                        this.possibilitarRetorno_data.ie_marcado_retorno = false
                    } else if ([400, 401, 403].includes(response.status)) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string')
                                this.$toast.error(response.data.detail, { duration: 3000 })
                            else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        }
                    }
                })
            }
        }
    }
</script>
